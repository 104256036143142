import produce from 'immer';
import { FiltersProp } from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import { DEFAULT_JOBS_SORTING_KEY, DEFAULT_RESULT_SORTING_KEY } from '@constants/jobs';
import { IJobResultData } from '@constants/prototype';
import {
  CreateJobStep,
  DELETE_JOB,
  DELETE_MULTIPLE_JOBS,
  FULFILLED,
  GENERATE_SHARED_JOB_LINK,
  GET_JOB,
  GET_JOB_LIST_FILTER_TAGS,
  GET_JOB_LIST_FILTER_USER,
  GET_JOB_RESULTS,
  GET_JOB_RESULTS_FILTER_FILES,
  GET_JOB_TAGS,
  GET_JOBS,
  GET_QUERY_SET,
  GET_QUERY_SETS,
  GET_SHARED_JOB,
  IAPIPaginationData,
  IJob,
  IJobQueryResult,
  IPipeline,
  IQuerySet,
  ITag,
  IUserFilter,
  JobType,
  PENDING,
  QueryRunOption,
  REJECTED,
  RESET_CREATE_JOB_STATE,
  RESET_JOB_DETAILS_STATE,
  RESET_PROTOTYPE_LINK,
  RESET_SHARED_JOB_LINK,
  REVOKE_SHARED_JOB_LINK,
  SELECT_JOB_LIST_SORT_VALUE,
  SELECT_JOB_PIPELINE,
  SELECT_JOB_TAGS,
  SELECT_JOB_TYPE,
  SELECT_QUERY_RUN,
  SELECT_QUERY_SET_FILE,
  SELECT_RESULTS_SORT_VALUE,
  SET_CREATE_JOB_STEP,
  SET_JOB_NAME,
  SET_OPEN_CREATE_JOB_DRAWER,
  START_JOB,
  START_MULTIPLE_JOBS,
} from '@redux/types/types';

interface IInitialStateProps {
  job: IJob | null;
  jobs: IAPIPaginationData<IJob[]>;
  jobResults: IAPIPaginationData<IJobQueryResult[]>;
  fetchJobsStatus: StatusCodes;
  fetchJobResultsStatus: StatusCodes;
  actionStatus: StatusCodes;
  querySet: IQuerySet | null;
  querySets: IAPIPaginationData<IQuerySet[]>;
  jobTags: ITag[];
  fetchQuerySetsStatus: StatusCodes;
  currentStep: CreateJobStep;
  openCreateJobDrawer: boolean;
  selectedJobType: JobType;
  selectedQueryRun: QueryRunOption;
  selectedQuerySet: string | null;
  selectedPipeline: IPipeline | null;
  selectedJobName: string | null;
  selectedTags: string[];
  loadingSharedJobResultStatus: StatusCodes;
  generateSharedJobResultStatus: StatusCodes;
  revokeSharedJobResultStatus: StatusCodes;
  currentSharedJobResult: IJobResultData | null;
  jobListFiltersValues: Record<string, FiltersProp | []>;
  jobListSortValue: string;
  jobResultsFiltersValues: Record<string, FiltersProp | []>;
  jobResultsSortValue: string;
}

export const initialState: IInitialStateProps = {
  job: null,
  jobs: {
    data: [],
    has_more: false,
    total: 0,
  },
  jobResults: {
    data: [],
    has_more: false,
    total: 0,
  },
  fetchJobsStatus: StatusCodes.IDLE,
  actionStatus: StatusCodes.IDLE,
  fetchJobResultsStatus: StatusCodes.IDLE,
  querySet: null,
  querySets: {
    data: [],
    has_more: false,
    total: 0,
  },
  fetchQuerySetsStatus: StatusCodes.IDLE,
  jobTags: [],
  jobListFiltersValues: {
    users: [],
    tags: [],
    statuses: [],
  },
  jobResultsFiltersValues: {
    files: [],
  },
  // Create new job
  currentStep: CreateJobStep.SELECT_JOB,
  openCreateJobDrawer: false,
  selectedJobType: JobType.BATCH_QA,
  selectedQueryRun: QueryRunOption.ACROSS_FILES,
  selectedQuerySet: null,
  selectedPipeline: null,
  selectedJobName: null,
  selectedTags: [],
  loadingSharedJobResultStatus: StatusCodes.IDLE,
  generateSharedJobResultStatus: StatusCodes.IDLE,
  revokeSharedJobResultStatus: StatusCodes.IDLE,
  currentSharedJobResult: null,
  jobListSortValue: DEFAULT_JOBS_SORTING_KEY,
  jobResultsSortValue: DEFAULT_RESULT_SORTING_KEY,
};

const jobsReducer = (state = initialState, action: any) => {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_CREATE_JOB_STATE:
        localDraft.currentStep = initialState.currentStep;
        localDraft.openCreateJobDrawer = initialState.openCreateJobDrawer;
        localDraft.selectedJobType = initialState.selectedJobType;
        localDraft.selectedQueryRun = initialState.selectedQueryRun;
        localDraft.selectedQuerySet = initialState.selectedQuerySet;
        localDraft.selectedPipeline = initialState.selectedPipeline;
        localDraft.selectedJobName = initialState.selectedJobName;
        localDraft.selectedTags = initialState.selectedTags;
        break;
      case RESET_JOB_DETAILS_STATE:
        localDraft.job = initialState.job;
        localDraft.jobResults = initialState.jobResults;
        localDraft.fetchJobResultsStatus = initialState.fetchJobResultsStatus;
        break;
      case `${GET_JOBS}/${PENDING}`:
        localDraft.fetchJobsStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_JOB}/${FULFILLED}`:
        localDraft.job = action.payload;
        break;
      case `${GET_JOBS}/${FULFILLED}`:
        localDraft.jobs = action.payload;
        localDraft.fetchJobsStatus = StatusCodes.SUCCESS;
        break;
      case `${GET_JOBS}/${REJECTED}`:
        localDraft.fetchJobsStatus = StatusCodes.ERROR;
        break;

      case `${GET_JOB_RESULTS}/${PENDING}`:
        localDraft.fetchJobResultsStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_JOB_RESULTS}/${FULFILLED}`:
        localDraft.jobResults = action.payload;
        localDraft.fetchJobResultsStatus = StatusCodes.SUCCESS;
        break;
      case `${GET_JOB_RESULTS}/${REJECTED}`:
        localDraft.fetchJobResultsStatus = StatusCodes.ERROR;
        break;

      case `${GET_QUERY_SETS}/${PENDING}`:
        localDraft.fetchQuerySetsStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_QUERY_SETS}/${FULFILLED}`:
        localDraft.querySets = action.payload;
        localDraft.fetchQuerySetsStatus = StatusCodes.SUCCESS;
        break;
      case `${GET_QUERY_SETS}/${REJECTED}`:
        localDraft.fetchQuerySetsStatus = StatusCodes.ERROR;
        break;
      case `${GET_QUERY_SET}/${FULFILLED}`:
        localDraft.querySet = action.payload;
        break;
      case `${GET_JOB_TAGS}/${FULFILLED}`:
        localDraft.jobTags = action.payload;
        break;
      case `${START_JOB}/${FULFILLED}`: {
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.job = action.payload;
        break;
      }
      case `${DELETE_JOB}/${FULFILLED}`: {
        localDraft.actionStatus = StatusCodes.SUCCESS;
        break;
      }
      case `${DELETE_MULTIPLE_JOBS}/${FULFILLED}`: {
        localDraft.actionStatus = StatusCodes.SUCCESS;
        break;
      }
      case `${START_MULTIPLE_JOBS}/${FULFILLED}`: {
        localDraft.actionStatus = StatusCodes.SUCCESS;
        break;
      }
      case SET_CREATE_JOB_STEP:
        localDraft.currentStep = action.payload;
        break;
      case SET_OPEN_CREATE_JOB_DRAWER:
        localDraft.openCreateJobDrawer = action.payload;
        break;
      case SELECT_JOB_TYPE:
        localDraft.selectedJobType = action.payload;
        break;
      case SELECT_QUERY_RUN:
        localDraft.selectedQueryRun = action.payload;
        break;
      case SELECT_QUERY_SET_FILE:
        localDraft.selectedQuerySet = action.payload;
        break;
      case SELECT_JOB_PIPELINE:
        localDraft.selectedPipeline = action.payload;
        break;
      case SET_JOB_NAME:
        localDraft.selectedJobName = action.payload;
        break;
      case SELECT_JOB_TAGS:
        localDraft.selectedTags = action.payload;
        break;
      case RESET_SHARED_JOB_LINK:
        localDraft.currentSharedJobResult = initialState.currentSharedJobResult;
        break;
      case RESET_PROTOTYPE_LINK:
        localDraft.currentSharedJobResult = initialState.currentSharedJobResult;
        break;
      case `${GENERATE_SHARED_JOB_LINK}/${PENDING}`:
        localDraft.generateSharedJobResultStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_SHARED_JOB}/${PENDING}`:
        localDraft.loadingSharedJobResultStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${REVOKE_SHARED_JOB_LINK}/${PENDING}`:
        localDraft.revokeSharedJobResultStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GENERATE_SHARED_JOB_LINK}/${FULFILLED}`:
        localDraft.generateSharedJobResultStatus = StatusCodes.SUCCESS;
        localDraft.currentSharedJobResult = action.payload;
        break;
      case `${GET_SHARED_JOB}/${FULFILLED}`:
        localDraft.loadingSharedJobResultStatus = StatusCodes.SUCCESS;
        localDraft.currentSharedJobResult = action.payload;
        break;
      case `${REVOKE_SHARED_JOB_LINK}/${FULFILLED}`:
        localDraft.revokeSharedJobResultStatus = StatusCodes.SUCCESS;
        localDraft.currentSharedJobResult = initialState.currentSharedJobResult;
        break;
      case `${GENERATE_SHARED_JOB_LINK}/${REJECTED}`:
        localDraft.generateSharedJobResultStatus = StatusCodes.ERROR;
        break;
      case `${GET_SHARED_JOB}/${REJECTED}`:
        localDraft.loadingSharedJobResultStatus = StatusCodes.ERROR;
        break;
      case `${GET_JOB_LIST_FILTER_TAGS}/${FULFILLED}`:
        localDraft.jobListFiltersValues = {
          ...localDraft.jobListFiltersValues,
          tags: action.payload.map(({ name: label, tag_id: key }: ITag) => ({ key, label })),
        };
        break;
      case `${GET_JOB_LIST_FILTER_USER}/${FULFILLED}`:
        localDraft.jobListFiltersValues = {
          ...localDraft.jobListFiltersValues,
          users: action.payload.map(({ created_by: user }: { created_by: IUserFilter }) => ({
            key: user.user_id,
            label: user.given_name,
          })),
        };
        break;

      case `${GET_JOB_RESULTS_FILTER_FILES}/${FULFILLED}`:
        localDraft.jobResultsFiltersValues = {
          ...localDraft.jobResultsFiltersValues,
          files: action.payload
            .filter(({ file }: { file: string }) => !!file)
            .map(({ file }: { file: string }) => ({
              key: file,
              label: file,
            })),
        };
        break;

      case SELECT_JOB_LIST_SORT_VALUE:
        localDraft.jobListSortValue = action.payload;
        break;
      case SELECT_RESULTS_SORT_VALUE:
        localDraft.jobResultsSortValue = action.payload;
        break;

      default:
        break;
    }

    return localDraft;
  });
};

export default jobsReducer;
