import { FileDrawerCollapseTabOptions, RunState } from '@redux/types/types';

export const PEOPLE_BLOCK = {
  TITLE: 'People in Your Organization',
  INVITE_USERS_LABEL: 'Invite  Users',
  ENABLE_USER_LABEL: 'Enable User',
  DISABLE_USER_LABEL: 'Disable User',
  YOU_LABEL: 'you',
  USERS_PER_PAGE: 10,
  LOAD_MORE_FAILURE: 'Loading more failed',
  CHANGE_LABEL: 'Change',
  CANCEL_LABEL: 'Cancel',
  REQUEST_ERROR: 'Server request error.',
  MAKE_ADMIN_LABEL: 'Make Admin',
  UNMAKE_ADMIN_LABEL: 'Unmake Admin',
  CLOSE_LABEL: 'Close',
  MAKE_ADMIN_SUCCESS: 'This user is an Admin now.',
  MAKE_ADMIN_FAILURE: `There was an error while changing the user role to Admin`,
  MAKE_ADMIN_MODAL_TEXT: ['- Settings of an organization', '- Upgrading your account'],
  SEND_INVITE_LABEL: 'Send Invite',
  SEND_INVITES_LABEL: 'Send Invites',
  INVITE_MODAL_SUBTITLE: `They'll be automatically added to your organization.`,
  SKIP_VERIFICATION_LABEL: 'Skip email verification',
  ADD_EMAIL_LABEL: 'Add email',
  ADD_USER_LABEL: 'Add user',
  INVITE_EMAIL_ERROR: "Enter the user's email address.",
  INVITE_VALIDATION_EMAIL_ERROR: 'The input is not a valid email address.',
  NAME_PLACEHOLDER: 'First name',
  SURNAME_PLACEHOLDER: 'Family name',
  INVITE_NAME_ERROR: 'First name is required.',
  INVITE_SURNAME_ERROR: 'Family name is required.',
  INVITE_ROLE_ERROR: 'Select a role',
  INVITE_EMAIL_SUBMIT_ERROR: 'Not all users have been invited.',
  INVITE_EMAIL_SUBMIT_SUCCESS: 'Invite has been sent, check your email.',
  VERIFICATION_EMAIL_SUBMIT_SUCCESS: 'Verification email has been sent, check your email.',
  INVITE_MESSAGE_DURATION: 5,
  MAX_EMAILS_COUNT: 10,
  INVITE_MODAL_TITLE: 'Invite people to',
  INVITE_MODAL_EMAILS_TITLE: 'Email address',
  ROLE_ADMIN: 'Admin',
  ROLE_USER: 'User',
  REMOVE_USER_CONFIRMATION_MESSAGE: 'Delete the user?',
  CONFIRM_BUTTON_LABEL: 'Yes',
  CANCEL_BUTTON_LABEL: 'No',
  REMOVE_USER_TOOLTIP: 'Remove user',
  CHANGE_USER_ROLE_TOOLTIP: 'Change role',
};

export const NUMBER_OF_USERS_PER_PAGE = 10;
export const NUMBER_OF_FILES_PER_PAGE = 10;

// Button labels
export const DONE_BUTTON_LABEL = 'Done';
export const APPLY_BUTTON_LABEL = 'Apply';
export const CANCEL_BUTTON_LABEL = 'Cancel';
export const UPDATE_BUTTON_LABEL = 'Update';
export const CREATE_BUTTON_LABEL = 'Create';
export const EDIT_BUTTON_LABEL = 'Edit';
export const BACK_BUTTON_LABEL = 'Back';
export const PREV_BUTTON_LABEL = 'Prev.';
export const NEXT_BUTTON_LABEL = 'Next';
export const YES_BUTTON_LABEL = 'Yes';
export const NO_BUTTON_LABEL = 'No';
export const DELETE_BUTTON_LABEL = 'Delete';
export const CUSTOM_BUTTON_LABEL = 'Custom';
export const SKIP_TOUR_BUTTON_LABEL = 'Skip tour';
export const DOWNLOAD_CSV_BUTTON_LABEL = 'Download as CSV';
export const DOCUMENT_PREVIEW_PREV_SOURCE_BUTTON_LABEL = 'Prev. Source';
export const DOCUMENT_PREVIEW_NEXT_SOURCE_BUTTON_LABEL = 'Next Source';
export const SCROLL_TO_RESULT_BUTTON_LABEL = 'Scroll to result';
export const SHARE_BUTTON_LABEL = 'Share';
export const UPLOAD_BUTTON_LABEL = 'Upload';
export const SUBMIT_BUTTON_LABEL = 'Submit';
export const SETTINGS_BUTTON_LABEL = 'Settings';
export const COPY_BUTTON_LABEL = 'Copy';
export const SAVE_BUTTON_LABEL = 'Save';
export const ADD_NEW_BUTTON_LABEL = 'Add new';
export const ADD_BUTTON_LABEL = 'Add';

// Labels

export const NEW_LABEL = 'New';
export const BETA_LABEL = 'Beta';
export const LEGACY_LABEL = 'Legacy';

export const DOCUMENT_PREVIEW_SOURCE_LABEL = 'Source';
export const PROMPT_MODAL_TITLE = 'Prompt';

export const UNEXPECTED_ERROR_LABEL = 'An unexpected error occured';
export const UNEXPECTED_ERROR_DESCRIPTION =
  'Sorry for the inconvenience. If the problem persists, contact customer services.';

export const NOT_FOUND_PROTOTYPE_ERROR_LABEL = 'Prototype not found';
export const NOT_FOUND_PROTOTYPE_ERROR_DESCRIPTION =
  "We couldn't find this prototype. Contact the prototype owner to check if it still exists and if your link is still valid.";

export const HELP_LABEL = 'Help';
export const OPTIONAL_LABEL = 'Optional';
export const NOT_AVAILABLE_LABEL = 'N/A';

export const NO_RELEVANT_ANSWER_LABEL = 'There is no relevant answer.';
export const PREVIEW_BUTTON_LABEL = 'Preview';

export const ANSWER_LABEL = 'Answer';
export const REFERENCE_LABEL = 'Reference';

export const NEW_TAG_LABEL = 'New Tag';
export const VIEW_METADATA_BUTTON_LABEL = 'View Metadata';
export const METADATA_MODAL_TITLE = 'Metadata';

export const PREVENT_PAGE_EXIT_MODAL_TITLE = 'Unsaved changes';
export const PREVENT_PAGE_EXIT_MODAL_OK_BUTTON_LABEL = 'Leave';
export const PREVENT_PAGE_EXIT_MODAL_CANCEL_BUTTON_LABEL = 'Stay';

export const FILTERS_TITLE = 'Filters';
export const NO_DATA_AVAILABLE = 'No data available';

export const SWITCHED_WORKSPACE_ALERT = 'Switched to %workspace% workspace';

export const SETTINGS_LABEL = 'Settings';
export const STYLE_LABEL = 'Style';

export const FAILED_TO_LOAD_PDF_ERROR = 'Unable to load the file';

export const DOCUMENTATION_LINK_LABEL = 'documentation';

export const RUN_STATUS_TAG_MAP = {
  [RunState.CREATED]: {
    label: 'Draft',
    color: 'default',
  },
  [RunState.STARTED]: {
    label: 'Running',
    color: 'processing',
  },
  [RunState.FAILED]: {
    label: 'Failed',
    color: 'error',
  },
  [RunState.ENDED]: {
    label: 'Completed',
    color: 'success',
  },
};

export const TAGS_SELECT_DESCRIPTION = 'Type the name and press enter to add a new tag.';

export const COMMON_FORM_ERRORS = {
  INVALID_CHARACTERS: 'Only use alphanumeric characters and underscore (_) or dash (-).',
};

export const COMING_SOON_LABEL = 'Coming soon';

// Files

export const FILE_DRAWER_COLLAPSE_TABS = [
  {
    label: 'File',
    key: FileDrawerCollapseTabOptions.FILE,
  },
  {
    label: 'Document',
    key: FileDrawerCollapseTabOptions.DOCUMENT,
  },
];

export const SHRINK_LABEL = 'Shrink';
export const EXPAND_LABEL = 'Expand';

export const RAW_FORMAT_LABEL = 'Raw %format%';

export const DEEPSET_CLOUD_V2_RELEASE_LOCAL_STORAGE_KEY = 'deepsetCloudV2ReleaseMessage';
export const DOCS_DEEPSET_CLOUD_V2_LINK = 'v2.0/docs/deepset-cloud-20';
export const VERSION2_DOCS_LABEL = `what's possible in v2.0`;
