import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { message, Modal } from 'antd';
import { auth } from '@utils/auth';
import { Navigator } from '@utils/navigator';
import { UserRoles } from '@constants/enum/common';
import { getWorkspaces } from '@redux/actions/organizationActions';
import { fetchUserInfo } from '@redux/actions/userActions';
import { userSelector } from '@redux/selectors/userSelectors';
import { IUserData } from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import PrivateRoute from '@components/common/PrivateRoute';
import NotificationCenter from '@components/notificationCenter/notificationCenter';
import LoginComponent from '@pages/auth/Login';
import LogoutComponent from '@pages/auth/Logout';
import ErrorPage from '@pages/error/ErrorPage';
import StudioUserDeletedPage from '@src/modules/PipelineStudio/pages/studioUserDeleted/studioUserDeletedPage';

const PipelineStudioPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PipelineStudio" */ '@modules/PipelineStudio/pages/pipelineStudio/pipelineStudioPage'
    ),
);

const DeployPipelineOptionsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DeployPipelineOptions" */ '@modules/PipelineStudio/pages/deployPipelineOptions/DeployPipelineOptionsPage'
    ),
);

message.config({
  maxCount: 1,
  duration: 5,
});

const StudioRoutes: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [redirectModal, modalContextHolder] = Modal.useModal();
  const { user, isAuthenticated, getAccessTokenSilently, logout, loginWithRedirect, isLoading } =
    useAuth0();
  const { role }: IUserData = useSelector(userSelector);

  const handleRedirect = () => {
    redirectModal.confirm({
      title: 'Redirect to deepset Cloud?',
      content:
        'As a deepset Cloud user you can use studio within your organization and leverage more features only available to dC.',
      onOk: () => {
        Modal.destroyAll();
        window.location.href = 'https://cloud.deepset.ai/pipelines/studio';
      },
      onCancel: () => {
        Modal.destroyAll();
      },
      okText: 'Go to deepset Cloud',
      cancelText: 'Stay here',
      centered: true,
    });
  };

  useEffect(() => {
    auth.setAccessTokenSilently(getAccessTokenSilently);
    auth.setloginWithRedirect(loginWithRedirect);
    auth.setLogout(logout);
  }, [getAccessTokenSilently, loginWithRedirect, logout]);

  useEffect(() => {
    Navigator.setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(fetchUserInfo({ authUser: user }));
      dispatch(getWorkspaces());
    }
    if (role === UserRoles.ADMIN) handleRedirect();
  }, [isAuthenticated, user]);

  if (isLoading) return <></>;

  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <NotificationCenter />
      {modalContextHolder}
      <Routes>
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/logout" element={<LogoutComponent />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/user-deleted" element={<StudioUserDeletedPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute
              component={() => (
                <React.Suspense fallback={<LoadingIndicator />}>
                  <PipelineStudioPage />
                </React.Suspense>
              )}
              roles={[UserRoles.ADMIN, UserRoles.PIPELINE_STUDIO]}
            />
          }
        />
        <Route
          path="/deploy-pipeline-options"
          element={
            <PrivateRoute
              component={() => (
                <React.Suspense fallback={<LoadingIndicator />}>
                  <DeployPipelineOptionsPage />
                </React.Suspense>
              )}
              roles={[UserRoles.ADMIN, UserRoles.PIPELINE_STUDIO]}
            />
          }
        />
        {/* redirect for 404 pages */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Sentry.ErrorBoundary>
  );
};

export default StudioRoutes;
