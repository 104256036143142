import produce from 'immer';
import { DEFAULT_FILE_SORTING_KEY, FILE_DELETE_SUCCESS_MESSAGE } from '@constants/data-page';
import { MessageCodes, StatusCodes } from '@src/constants/enum/common';
import { normalizeErrorMessage } from '@src/utils/error';
import {
  DELETE_ALL_FILES,
  DELETE_FILE,
  DELETE_MULTIPLE_FILES,
  DOWNLOAD_FILE,
  FULFILLED,
  GET_FILE_CONTENT,
  GET_WORKSPACE_FILES,
  IAPIPaginationData,
  IFile,
  IMessage,
  ISelectedFileDocument,
  PENDING,
  REJECTED,
  RESET_FILE_CONTENT,
  RESET_FILES_MESSAGE,
  SELECT_FILE_DOCUMENT,
  SELECT_FILE_SORT_VALUE,
  UPDATE_FILE_META,
} from '../types/types';

interface IInitialStateProps {
  files: IAPIPaginationData<IFile[]>;
  message: IMessage;
  actionStatus: StatusCodes;
  selectedFileDocument: ISelectedFileDocument | null;
  fileContent: string | null;
  getFileContentStatus: StatusCodes;
  fetchStatus: StatusCodes;
  updateFileMetaStatus: StatusCodes;
  sortValue: string;
}

export const initialState: IInitialStateProps = {
  files: {
    data: [],
    has_more: false,
    total: 0,
  },
  message: {
    type: MessageCodes.INFO,
    content: '',
  },
  actionStatus: StatusCodes.IDLE,
  selectedFileDocument: null,
  fileContent: null,
  getFileContentStatus: StatusCodes.IDLE,
  fetchStatus: StatusCodes.IDLE,
  updateFileMetaStatus: StatusCodes.IDLE,
  sortValue: DEFAULT_FILE_SORTING_KEY,
};

function layoutReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_FILES_MESSAGE:
        localDraft.message = initialState.message;
        localDraft.actionStatus = StatusCodes.IDLE;
        localDraft.fetchStatus = StatusCodes.IDLE;
        break;
      case RESET_FILE_CONTENT:
        localDraft.fileContent = initialState.fileContent;
        localDraft.getFileContentStatus = StatusCodes.IDLE;
        break;
      case SELECT_FILE_SORT_VALUE:
        localDraft.sortValue = action.payload;
        break;
      case SELECT_FILE_DOCUMENT:
        localDraft.selectedFileDocument = action.payload;
        break;
      case `${DELETE_FILE}/${PENDING}`:
      case `${DELETE_MULTIPLE_FILES}/${PENDING}`:
      case `${DELETE_ALL_FILES}/${PENDING}`:
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        localDraft.message = {
          type: MessageCodes.INFO,
          content: 'Files are being deleted.',
        };
        break;
      case `${GET_FILE_CONTENT}/${PENDING}`:
        localDraft.getFileContentStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${DOWNLOAD_FILE}/${PENDING}`:
        localDraft.message = initialState.message;
        localDraft.actionStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_FILE_CONTENT}/${FULFILLED}`:
        localDraft.getFileContentStatus = StatusCodes.SUCCESS;
        localDraft.fileContent = action.payload;
        break;
      case `${GET_FILE_CONTENT}/${REJECTED}`:
        localDraft.getFileContentStatus = StatusCodes.ERROR;
        break;
      case `${DOWNLOAD_FILE}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        break;
      case `${GET_WORKSPACE_FILES}/${PENDING}`:
        localDraft.fetchStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_WORKSPACE_FILES}/${FULFILLED}`:
        localDraft.fetchStatus = StatusCodes.SUCCESS;
        localDraft.files = action.payload;
        break;
      case `${DELETE_FILE}/${FULFILLED}`:
      case `${DELETE_ALL_FILES}/${FULFILLED}`:
      case `${DELETE_MULTIPLE_FILES}/${FULFILLED}`:
        localDraft.actionStatus = StatusCodes.SUCCESS;
        localDraft.message = {
          type: MessageCodes.SUCCESS,
          content: FILE_DELETE_SUCCESS_MESSAGE,
        };
        break;

      case `${GET_WORKSPACE_FILES}/${REJECTED}`:
        localDraft.fetchStatus = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;
      case `${DELETE_FILE}/${REJECTED}`:
      case `${DELETE_MULTIPLE_FILES}/${REJECTED}`:
      case `${DELETE_ALL_FILES}/${REJECTED}`:
      case `${DOWNLOAD_FILE}/${REJECTED}`:
        localDraft.actionStatus = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;

      case `${UPDATE_FILE_META}/${PENDING}`:
        localDraft.updateFileMetaStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${UPDATE_FILE_META}/${FULFILLED}`:
        localDraft.updateFileMetaStatus = StatusCodes.SUCCESS;
        break;
      case `${UPDATE_FILE_META}/${REJECTED}`:
        localDraft.updateFileMetaStatus = StatusCodes.ERROR;
        break;

      default:
        break;
    }

    return localDraft;
  });
}

export default layoutReducer;
