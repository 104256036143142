import { createLink, interpolateString } from '@utils/string';
import { JobType, QueryRunOption } from '@redux/types/types';
import { DOCS_URL } from './constant';

export const JOBS_TABLE_PAGE_SIZE = 10;

export const DETAILS_BUTTON_LABEL = 'Show Details';
export const DELETE_JOB_POPCONFIRM_MESSAGE = 'Delete this job?';
export const START_BUTTON_LABEL = 'Start';
export const TRY_AGAIN_BUTTON_LABEL = 'Try Again';
export const DELETE_SELECTED_JOB = 'Delete %total% job';
export const DELETE_SELECTED_JOBS = 'Delete %total% jobs';
export const JOB_CREATED_MESSAGE = 'Job successfully created.';
export const JOB_STARTED_MESSAGE = 'Job successfully started.';
export const JOBS_STARTED_MESSAGE = 'Jobs successfully started.';
export const JOB_DELETED_MESSAGE = 'Job successfully deleted.';
export const JOBS_DELETED_MESSAGE = 'Jobs successfully deleted.';
export const SAVE_DRAFT_BUTTON_LABEL = 'Save as Draft';
export const START_JOB_BUTTON_LABEL = 'Start Job';

export const EMPTY_JOBS_TITLE = 'No jobs found';
export const EMPTY_JOBS_DESCRIPTION = interpolateString(
  'Set up jobs to easily handle batch queries and seamlessly gather consistent information across various datasets. Check out the %link% to get a better understanding of what a job is.',
  {
    link: createLink(`${DOCS_URL}docs/about-jobs`, 'documentation'),
  },
);

export const JOBS_TABLE_COLUMNS_TITLE = {
  NAME: 'Name',
  TAGS: 'Tags',
  EXECUTED_AT: 'Executed at',
  REQUESTS: 'Requests',
  STATUS: 'Status',
  CREATED_BY: 'Created by',
};

export const CREATE_JOB_BUTTON_LABEL = 'New Job';

export const CREATE_JOB_DRAWER_TITLE = 'Create a job';
export const CREATE_JOB_STEPS_INFO = {
  JOB_SELECTION: {
    STEP_TITLE: 'Job',
    TITLE: 'Select a job',
    DESCRIPTION:
      'Choose from specialized job processes tailored to specific use cases to support your project objectives.',
    FORM: {
      JOB_TYPE_LABEL: 'Job type',
      QUERY_RUN_LABEL: 'Run mode',
      QUERY_RUN_TOOLTIP:
        'Choose Run once to search all files and get a single answer for each query. Choose Repeat queries per file to run each query on every file resulting in one answer per file.',
    },
  },
  QUERY_SET_SELECTION: {
    STEP_TITLE: 'Query set',
    TITLE: 'Query set',
    DESCRIPTION: interpolateString(
      'Upload a UTF8-encoded CSV file with the queries for this job, including any filters, attributes, or headers. %link% our template to help you get started.',
      {
        link: createLink(`${DOCS_URL}docs/prepare-a-query-set#template`, 'Download'),
      },
    ),
    FORM: {
      PREVIOUSLY_QUERY_SETS_LABEL: 'Previously uploaded query sets',
      PREVIOUSLY_QUERY_SETS_PLACEHOLDER: 'Select query set',
    },
  },
  PIPELINE_SELECTION: {
    STEP_TITLE: 'Pipeline',
    TITLE: 'Choose a pipeline',
    DESCRIPTION: 'This is the pipeline the job will use.',
  },
  JOB_NAMING: {
    STEP_TITLE: 'Naming',
    TITLE: 'Name your job',
    FORM: {
      NAME_LABEL: 'Name',
      NAME_PLACEHOLDER: 'Give your job a unique name',
      TAGS_LABEL: 'Tags',
      TAGS_PLACEHOLDER: 'Use keywords to describe your job',
    },
  },
};

export const JOB_TYPE_LABELS = {
  [JobType.BATCH_QA]: 'Batch Question Answering',
};

export const QUERY_RUN_OPTION_LABELS = {
  [QueryRunOption.ACROSS_FILES]: 'Run once',
  [QueryRunOption.AGAINST_EACH_FILE]: 'Repeat queries per file',
};

export const UNSUPPORTED_PIPELINE_SELECTION_WARNING_MESSAGE =
  'Jobs can only use pipelines that return answers, like RAG, chat, or extractive question answering';

// Details

export const DELETE_CONFIRMATION_MESSAGE = 'Are you sure you want to delete?';
export const DURATION_LABEL = 'Duration';
export const PIPELINE_LABEL = 'Pipeline';
export const QUERY_SET_LABEL = 'Query Set';
export const JOB_TYPE_LABEL = 'Job Type';
export const RUN_QUERIES_LABEL = 'Run Mode';
export const REQUESTS_LABEL = 'Number of requests';
export const QUERY_RESOLUTION_LABEL = 'Answer rate';
export const QUERY_RESOLUTION_TOOLTIP =
  'Measures the percentage of queries that resulted in an actual answer.';
export const FAIL_ALERT_MESSAGE = 'This job run has failed';
export const FAIL_ALERT_DESCRIPTION = 'Please try again or contact support.';

// Results

export const RESULTS_HEADER = 'Results';
export const RESULTS_TITLE_HEADER = 'Results Title';
export const RESULTS_TITLE_INPUT_PLACEHOLDER = 'Enter a title for the results';
export const RESULTS_FOOTER_LABEL = 'Job Report';

export const JOB_RESULT_TABLE_COLUMNS_TITLE = {
  QUERY: 'Query',
  ANSWER: 'Answer',
  CONTEXT: 'Context',
  FILE: 'File',
  FILTERS: 'Filters',
  RELEVANCE: 'Relevance',
  RANK: 'Rank',
  QUERY_BY: 'Query by',
  PROMPT: 'Prompt',
  NO_ANSWER: 'No answer',
  DURATION: 'Duration',
  FEEDBACK_RATING: 'Feedback Rating',
};

export const SHARE_JOB_MODAL_BANNER = 'Share this job as a formatted HTML page. No login required.';
export const SHARE_JOB_MODAL_TITLE = 'Share results';
export const SHARE_JOB_MODAL_EXPIRED_BANNER =
  'This link has expired and is no longer accessible. To share this job again you need to generate another link.';

// Filtering

export const SEARCH_JOB_LIST_PLACEHOLDER = 'Find jobs';
export const SEARCH_JOB_RESULTS_PLACEHOLDER = 'Find queries';

export const JOB_LIST_FILTERS_STATUS_LABEL = 'Status';
export const JOB_LIST_FILTERS_USER_LABEL = 'Created By';
export const JOB_LIST_FILTERS_TAGS_LABEL = 'Tags';
export const JOB_LIST_FILTERS_FIELD_KEYS = {
  STATUS: 'status',
  USER: 'created_by',
  TAGS: 'tags/tag_id',
};
export const JOB_RESULTS_FILTERS_FILES_LABEL = 'File';
export const JOB_RESULTS_FILTERS_FIELD_KEYS = {
  FILE: 'file',
};

// Sorting

export const DEFAULT_JOBS_SORTING_KEY = 'created_at_desc';
export const JOBS_SORTING_OPTIONS = [
  { key: 'created_at_desc', label: 'Newest to Oldest' },
  { key: 'created_at_asc', label: 'Oldest to Newest' },
  { key: 'name_asc', label: 'Name A to Z' },
  { key: 'name_desc', label: 'Name Z to A' },
];
export const JOBS_SORTING_PARAMS_BY_KEY = {
  created_at_desc: {
    field: 'created_at',
    order: 'DESC',
  },
  created_at_asc: {
    field: 'created_at',
    order: 'ASC',
  },
  name_asc: {
    field: 'name',
    order: 'ASC',
  },
  name_desc: {
    field: 'name',
    order: 'DESC',
  },
};

export const DEFAULT_RESULT_SORTING_KEY = 'created_at_desc';
export const RESULT_SORTING_OPTIONS = [
  { key: 'created_at_desc', label: 'Newest to Oldest' },
  { key: 'created_at_asc', label: 'Oldest to Newest' },
  { key: 'query_asc', label: 'Query A to Z' },
  { key: 'query_desc', label: 'Query Z to A' },
];
export const RESULT_SORTING_PARAMS_BY_KEY = {
  created_at_desc: {
    field: 'created_at',
    order: 'DESC',
  },
  created_at_asc: {
    field: 'created_at',
    order: 'ASC',
  },
  query_asc: {
    field: 'query',
    order: 'ASC',
  },
  query_desc: {
    field: 'query',
    order: 'DESC',
  },
};
