export enum UserEventType {
  CLICK = 'click',
  KEYDOWN = 'keydown',
  ERROR = 'error',
  DROP = 'drop',
  CONNECT = 'connect',
}

export enum EventControlComponent {
  PREDICTIONS_TABLE = 'predictions-table',
  PIPELINE_FEEDBACK_TABLE = 'pipeline-feedback-table',
  GUIDED_WORKFLOW = 'guided-workflow',
  LOGIN = 'login',
  PIPELINE_TEMPLATE = 'pipeline-template',
  GROUNDEDNESS = 'groundedness',
  SEARCH_RESULT = 'search-result',
  REFERENCE_DRAWER = 'reference-drawer',
  QUERY_DETAILED_DRAWER = 'query-detailed-drawer',
  STUDIO = 'studio',
}

export enum EventControlElement {
  DOWNLOAD = 'download',
  NODE_TAB = 'node-tab',
  FILTERS = 'filters',
  SELECT = 'select',
  TAB = 'tab',
}

export enum EventControlDataTableElement {
  SEARCH = 'search',
  PAGINATION = 'pagination',
  SORT = 'sort',
  FILTER = 'filter',
  CLEAR_FILTER = 'clear-filter',
  CLEAR_ALL_FILTERS = 'clear-all-filters',
  ITEM = 'item',
}

export enum EventControlGuidedWorkflowElement {
  NEXT = 'next',
  BACK = 'back',
  EDIT = 'edit',
  TRY = 'try',
  SHARE = 'share',
  EXIT = 'exit',
  DEPLOY = 'deploy',
  INDEX = 'index',
  TRY_AGAIN = 'try-again',
  UPLOAD_FILES = 'upload-files',
}

export enum EventControlPipelineTemplateElement {
  SELECT_PIPELINE_TEMPLATE = 'select-pipeline-template',
}

export enum EventControlSearchResultElement {
  VIEW_REFERENCE = 'view-reference',
  VIEW_FILE_SOURCE = 'view-file-source',
  VIEW_DOC_SOURCE = 'view-doc-source',
}

export enum EventControlStudioElement {
  COMPONENT_STUDIO = 'component',
  OPEN_DOWNLOAD_MODAL_STUDIO = 'open-download-modal',
  COPY_CODE_STUDIO = 'copy-code',
  DOWNLOAD_CODE_STUDIO = 'download-code',
  LOGIN = 'login',
  SAVE_PIPELINE = 'save-pipeline',
  DEPLOY_PIPELINE = 'deploy-pipeline',
  DOCUMENTATION_LINK = 'documentation-link',
  DISCORD_LINK = 'discord-link',
}
