import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { auth } from '@utils/auth';
import DeepsetCloudLogo from '@assets/deepset-cloud-signet-blue-onwhite.svg?react';
import styles from './studioUserDeleted.module.css';

const StudioUserDeletedPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      // TODO: Change the navigate to sign up once is ready
      auth.logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
    };
  }, []);

  return (
    <div className={styles.userDeletedContainer}>
      <div className={styles.userDeletedCard}>
        <DeepsetCloudLogo className={styles.deepsetCloudLogo} />
        <div className={styles.cardTitle}>Account deleted</div>
        <div className={styles.cardSubtitle}>
          Your account has been permanently removed and your data erased.
        </div>
        {/* TODO: Change the navigate to sign up once is ready*/}
        <div>
          Changed your mind?{' '}
          <Button onClick={() => navigate('/login')} className={styles.signUpButton} type="link">
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StudioUserDeletedPage;
