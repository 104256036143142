import produce from 'immer';
import { normalizeErrorMessage } from '@utils/error';
import { getWorkspaceIdleTimeout } from '@utils/workspace';
import { MessageCodes, StatusCodes } from '@constants/enum/common';
import { DEFAULT_WORKSPACE } from '@constants/workspace';
import {
  ADD_WORKSPACE,
  DELETE_MODEL_REGISTRY_TOKEN,
  DELETE_WORKSPACE,
  FETCH_API_KEYS,
  FETCH_MODEL_REGISTRY_TOKEN,
  FETCH_USERS,
  FETCH_WORKSPACES,
  FULFILLED,
  IAPIKeysData,
  IMessage,
  IModelRegistryToken,
  IOrganizationData,
  IUserData,
  ModelProviders,
  PENDING,
  POST_MODEL_REGISTRY_TOKEN,
  REJECTED,
  RESET_ORGANIZATION_INFO,
  RESET_ORGANIZATION_MESSAGE,
  SET_CURRENT_WORKSPACE,
  SET_ORGANIZATION_INFO,
} from '@redux/types/types';

export interface IWorkspaceItem {
  name: string;
  workspace_id: string;
  languages: any;
  metadata_fields: any;
  default_idle_timeout_in_seconds: number;
}

interface IInitialStateProps {
  organization: IOrganizationData;
  workspaces: IWorkspaceItem[];
  currentWorkspace: string;
  currentWorkspaceDefaultIdleTimeout: number | null;
  allUsers: {
    users: IUserData[];
    total: number;
  };
  connections: {
    apiKeys: IAPIKeysData;
    modelRegistryTokens: IModelRegistryToken[];
  };
  message: IMessage;
  addWorkspaceStatus: StatusCodes;
  currentDeletingWorkspaces: Record<string, string>;
}

export const initialState: IInitialStateProps = {
  organization: {
    organizationName: '',
    organizationId: '',
  },
  workspaces: [],
  currentWorkspace: DEFAULT_WORKSPACE,
  currentWorkspaceDefaultIdleTimeout: null,
  allUsers: {
    users: [],
    total: 1,
  },
  connections: {
    apiKeys: {
      data: [],
      has_more: false,
      total: 0,
    },
    modelRegistryTokens: [],
  },
  message: {
    type: MessageCodes.INFO,
    content: '',
  },
  addWorkspaceStatus: StatusCodes.IDLE,
  currentDeletingWorkspaces: {},
};

function organizationReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_ORGANIZATION_MESSAGE:
        localDraft.message = initialState.message;
        break;

      case SET_ORGANIZATION_INFO:
        localDraft.organization = action.payload;
        break;

      case RESET_ORGANIZATION_INFO:
        localDraft.organization = initialState.organization;
        break;

      case `${FETCH_USERS}/${FULFILLED}`: {
        const { users, totalUsers } = action.payload;
        localDraft.allUsers = {
          users,
          total: totalUsers,
        };
        break;
      }
      case `${FETCH_WORKSPACES}/${FULFILLED}`: {
        localDraft.currentWorkspaceDefaultIdleTimeout = getWorkspaceIdleTimeout(
          action.payload,
          localDraft.currentWorkspace,
        );
        localDraft.workspaces = action.payload;
        break;
      }
      case SET_CURRENT_WORKSPACE:
        localDraft.currentWorkspaceDefaultIdleTimeout = getWorkspaceIdleTimeout(
          localDraft.workspaces,
          action.payload,
        );
        localDraft.currentWorkspace = action.payload;
        break;
      case `${ADD_WORKSPACE}/${PENDING}`: {
        localDraft.addWorkspaceStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${ADD_WORKSPACE}/${FULFILLED}`: {
        localDraft.addWorkspaceStatus = StatusCodes.SUCCESS;
        break;
      }
      case `${ADD_WORKSPACE}/${REJECTED}`: {
        localDraft.addWorkspaceStatus = StatusCodes.ERROR;
        break;
      }
      case `${DELETE_WORKSPACE}/${PENDING}`: {
        const workspace = action.meta.arg;
        localDraft.currentDeletingWorkspaces[workspace] = workspace;
        break;
      }
      case `${DELETE_WORKSPACE}/${FULFILLED}`: {
        const workspace = action.meta.arg;
        delete localDraft.currentDeletingWorkspaces[workspace];
        break;
      }
      case `${DELETE_WORKSPACE}/${REJECTED}`: {
        const workspace = action.meta.arg;
        delete localDraft.currentDeletingWorkspaces[workspace];
        break;
      }
      case `${FETCH_API_KEYS}/${FULFILLED}`: {
        localDraft.connections.apiKeys = action.payload;
        break;
      }

      case `${FETCH_MODEL_REGISTRY_TOKEN}/${FULFILLED}`: {
        localDraft.connections.modelRegistryTokens = action.payload;
        break;
      }

      case `${POST_MODEL_REGISTRY_TOKEN}/${PENDING}`: {
        localDraft.message = initialState.message;
        break;
      }
      case `${POST_MODEL_REGISTRY_TOKEN}/${FULFILLED}`: {
        const { modelProvider }: { modelProvider: ModelProviders } = action.meta.arg;
        // Only to update the UI we add fake data here for given provider as we don't get it from response
        localDraft.connections.modelRegistryTokens = [
          ...localDraft.connections.modelRegistryTokens,
          {
            provider: modelProvider,
            provider_domain: '',
            tokenId: '',
            invalid: false,
          },
        ];
        break;
      }
      case `${POST_MODEL_REGISTRY_TOKEN}/${REJECTED}`: {
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;
      }
      case `${DELETE_MODEL_REGISTRY_TOKEN}/${FULFILLED}`: {
        const modelProvider: ModelProviders = action.meta.arg;
        localDraft.connections.modelRegistryTokens =
          localDraft.connections.modelRegistryTokens.filter(
            ({ provider }) => provider !== modelProvider,
          );
        break;
      }

      default:
        break;
    }
  });
}

export default organizationReducer;
