// New implementation of pipeline templates
import React from 'react';
import { CN, DE, ES, FR, IN, IT, JP, NL, PT, RU, SA, US, VN } from 'country-flag-icons/react/3x2';
import { DeepsetCloudVersion, PipelineTemplatesCategory } from '@redux/types/types';

export const BY_USER_LABEL = 'by';
export const CREATED_BY_USER_LABEL = 'Created by';
export const CATEGORY_ALL_TEMPLATES = 'All Templates';

export const VIEW_DETAILS_BUTTON_LABEL = 'View Details';
export const USE_TEMPLATE_BUTTON_LABEL = 'Use Template';
export const SEE_ALL_LABEL = 'See all';
export const CREATE_EMPTY_FILE_BUTTON_LABEL = 'Create empty pipeline';
export const PIPELINE_PREVIEW_TITLE = 'Pipeline Preview';
export const MORE_TEMPLATES_LABEL = 'Similar templates';
export const SEARCH_TEMPLATES_PLACEHOLDER = 'Find templates';
export const CLEAR_ALL_FILTERS_BUTTON_LABEL = 'clear all filters';
export const DEEPSET_CLOUD_VERSION_LABEL = 'deepset Cloud %version%';
export const EMPTY_TEMPLATES_LIST_LABEL = `We're working on new %categoryName% templates. In the meantime, feel free to explore other categories or check back here later.`;

export const DEEPSET_CLOUD_VERSION_LABELS = {
  [DeepsetCloudVersion.V1]: '1.0',
  [DeepsetCloudVersion.V2]: '2.0',
};

export const VERSION_TABS_LABELS = [
  { key: DeepsetCloudVersion.V2, label: 'deepset Cloud 2.0' },
  { key: DeepsetCloudVersion.V1, label: 'deepset Cloud 1.0' },
];

export const DEEPSET_CLOUD_VERSION_TITLES_AND_DESCRIPTIONS = {
  [DeepsetCloudVersion.V1]: {
    title: 'deepset Cloud 1.0',
    description: `With this robust and stable deepset Cloud 1.0 version, you can build production-ready LLM apps and advanced search systems using the pipelines under the hood. In 1.0 pipelines, data streams from one node to the next, ensuring a smooth and efficient workflow.`,
  },
  [DeepsetCloudVersion.V2]: {
    title: 'deepset Cloud 2.0',
    description: `The deepset Cloud 2.0 Beta release introduces flexible pipelines that support simultaneous flows and loops and can handle multiple functions. Indexing and query pipelines are separated for enhanced performance. Additionally, you'll find improved, more powerful pipeline components to boost your workflows.`,
  },
};

export const CATEGORY_LABELS = {
  [PipelineTemplatesCategory.RECOMMENDED]: 'Recommended',
  [PipelineTemplatesCategory.DOCUMENT_SEARCH]: 'Document Search',
  [PipelineTemplatesCategory.BASIC_QA]: 'Basic QA',
  [PipelineTemplatesCategory.ADVANCED_QA]: 'Advanced QA',
  [PipelineTemplatesCategory.CONVERSATIONAL]: 'Conversational',
  [PipelineTemplatesCategory.TEXT_ANALYSIS]: 'Text Analysis',
  [PipelineTemplatesCategory.VISUAL_QA]: 'Visual QA',
  [PipelineTemplatesCategory.TEXT_TO_SQL]: 'Text-to-SQL',
};

export enum PipelineTemplateMetadataFields {
  BEST_FOR = 'Best for',
  POTENTIAL_APPLICATIONS = 'Potential applications',
  TAGS = 'Tags',
  EXPECTED_OUTPUT = 'Expected output',
  LANGUAGES = 'Languages',
  RECOMMENDED_DATASET = 'Recommended dataset',
  DEEPSET_CLOUD_VERSION = 'Version',
}

export const PIPELINE_TEMPLATE_LANGUAGES_CODES = {
  arabic: <SA />,
  italian: <IT />,
  spanish: <ES />,
  hindi: <IN />,
  dutch: <NL />,
  portuguese: <PT />,
  russian: <RU />,
  french: <FR />,
  english: <US />,
  vietnamese: <VN />,
  chinese: <CN />,
  japanese: <JP />,
  german: <DE />,
};

// Filtering pipeline templates

export const PIPELINE_TEMPLATES_FILTERS_TAGS_LABEL = 'Tags';
export const PIPELINE_TEMPLATES_FILTERS_FIELD_KEYS = {
  TAGS_ID: 'tags/tag_id',
};

export const EMPTY_FILTERED_PIPELINE_TEMPLATES = {
  TITLE: 'No templates match the current filters',
  DESCRIPTION:
    'To see more templates, try changing or removing some filters. You can also %clearFiltersLink% to view all available templates.',
};

export const EMPTY_SEARCHED_PIPELINE_TEMPLATES = {
  TITLE: 'No templates match the search criteria',
  DESCRIPTION: 'Try searching for something different.',
};

// Sorting pipeline templates

export const DEFAULT_SORTING_KEY = 'name_asc';
export const PIPELINE_TEMPLATES_SORTING_OPTIONS = [
  { key: 'name_asc', label: 'Name A to Z' },
  { key: 'name_desc', label: 'Name Z to A' },
];
export const SORTING_PARAMS_BY_KEY = {
  name_asc: {
    field: 'name',
    order: 'ASC',
  },
  name_desc: {
    field: 'name',
    order: 'DESC',
  },
};
