import produce from 'immer';
import {
  FETCH_USER_INFO,
  FULFILLED,
  IUserData,
  REJECTED,
  RESET_USER_INFO,
  SET_USER_INFO,
  UPDATE_USER_INFO,
} from '@redux/types/types';
import { UserRoles } from '@src/constants/enum/common';

interface IInitialStateProps {
  user: IUserData;
  loginError: boolean;
}

export const initialState: IInitialStateProps = {
  user: {
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    userID: '',
    organization: '',
    role: UserRoles.SEARCH_USER,
    isAdmin: false,
    isEmailVerified: false,
    workspaces: [],
  },
  loginError: false,
};

function userReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case SET_USER_INFO:
        localDraft.user = action.payload;
        break;

      case UPDATE_USER_INFO: {
        const currentUserState: any = state.user;
        localDraft.user = { ...currentUserState, ...action.payload };
        break;
      }

      case RESET_USER_INFO:
        localDraft.user = initialState.user;
        break;

      case `${FETCH_USER_INFO}/${REJECTED}`:
        localDraft.loginError = true;
        break;

      case `${FETCH_USER_INFO}/${FULFILLED}`:
        localDraft.loginError = false;
        localDraft.user = action.payload;
        break;

      default:
        break;
    }
  });
}

export default userReducer;
