import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { ConfigProvider, Spin } from 'antd';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import '@config/dayjs';
import '@config/monaco/setup';
import { dataDogRumConfig } from '@utils/datadog';
import { isErrorFromChromeExtension } from '@utils/sentry';
import { sendUserEventApi } from '@api/event';
import { EventControlComponent, EventControlStudioElement, UserEventType } from '@constants/event';
import { persistor, store } from '@redux/store';
import defaultTheme from '@styles/themes/default';
import './styles/main.scss';

import App from './StudioRoutes';

dataDogRumConfig();

const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
const sentryENV = import.meta.env.VITE_SENTRY_ENV;
const headlessBrowser = navigator.webdriver;

const render = () => {
  if (sentryDSN && sentryENV && !headlessBrowser) {
    Sentry.init({
      environment: sentryENV,
      dsn: sentryDSN,
      autoSessionTracking: true,
      integrations: [
        Sentry.browserTracingIntegration({
          enableInp: true,
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: sentryENV === 'prod' ? 0.8 : 1,
      replaysSessionSampleRate: sentryENV === 'prod' ? 0.1 : 0,
      replaysOnErrorSampleRate: 1,
      ignoreErrors: ['https://www.datadoghq-browser-agent.eu'],
      beforeSend(event) {
        if (isErrorFromChromeExtension(event)) return null;
        return event;
      },
    });
  }

  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE || import.meta.env.VITE_API_URL;
  const container = document.getElementById('root') as Element;
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <Auth0Provider
        domain={domain!}
        clientId={clientId!}
        cacheLocation="localstorage"
        onRedirectCallback={() => {
          sendUserEventApi({
            type: UserEventType.CLICK,
            control: `${EventControlComponent.STUDIO}/${EventControlStudioElement.LOGIN}`,
            properties: {
              version: 'standalone',
            },
          });
        }}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience,
        }}
      >
        <PersistGate
          loading={<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
          persistor={persistor}
        >
          <BrowserRouter>
            <ConfigProvider theme={defaultTheme}>
              <App />
            </ConfigProvider>
          </BrowserRouter>
        </PersistGate>
      </Auth0Provider>
    </Provider>,
  );
};

render();
